import useAccessContext from 'Hooks/useAccessContext'
import Layout from 'Layout/Layout/Layout'
import AccessCode from 'Pages/AccessCode/AccessCode'
// import Welcome from 'Pages/Welcome/Welcome'
// import { Route, Switch } from 'react-router-dom'
// import { isElectron } from 'Utils/isElectron'

const App = () => {
  const {
    accessState: { canAccess },
  } = useAccessContext()

  // if (isElectron()) {
  return canAccess ? <Layout /> : <AccessCode />
  // }

  // return (
  //   <Switch>
  //     <Route exact path="/welcome" component={() => <Welcome />} />

  //     <Route component={() => (canAccess ? <Layout /> : <AccessCode />)} />
  //   </Switch>
  // )
}

export default App
