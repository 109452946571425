import { useContext } from 'react'
import { AccessContext } from 'Context/AccessContext'

const useAccessContext = () => {
  const { accessState, access } = useContext(AccessContext)

  return {
    accessState,
    access,
  }
}

export default useAccessContext
