import LogoBayer from 'Assets/logo-bayer.png'
import { NavLink } from 'react-router-dom'

const listItems = [
  {
    icon: <span className="material-icons-outlined md-2xl">home</span>,
    to: '/dashboard',
    text: 'Home',
  },
  {
    icon: <span className="material-icons transform rotate-90">search</span>,
    to: '/ricerca-avanzata',
    text: 'Ricerca avanzata',
  },
  {
    icon: <span className="material-icons-outlined">import_contacts</span>,
    to: '/rcp',
    text: 'Rcp',
  },
  {
    icon: <span className="material-icons-outlined">info</span>,
    to: '/info',
    text: 'Info',
  },
]

const Aside = () => {
  return (
    <aside className="lg:col-span-1 lg:row-span-10 overflow-y-auto lg:flex lg:flex-col lg:justify-between border-r border-gray-200">
      <ul>
        {listItems.map((item) => (
          <NavLink
            key={item.text}
            to={item.to}
            className="px-4 py-2 lg:py-4 block uppercase"
            activeClassName="bg-green">
            <li className="flex items-center">
              <div className="mr-2 text-gray-500">{item.icon}</div> {item.text}
            </li>
          </NavLink>
        ))}
      </ul>

      <div className="p-5">
        <img
          src={LogoBayer}
          alt="bayer"
          width={40}
          className="mb-2 hidden lg:block"
        />
        <p className="text-xs">PP-CIPO-IT-0042-1</p>
      </div>
    </aside>
  )
}

export default Aside
