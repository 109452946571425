import PropTypes from 'prop-types'

const ErrorAlert = ({ message = null }) => {
  return (
    <div className="bg-red-200 p-5 rounded text-red-700">Errore: {message}</div>
  )
}

ErrorAlert.propTypes = {
  message: PropTypes.string,
}

export default ErrorAlert
