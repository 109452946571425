import Logo from 'Assets/logo.png'
import debounce from 'lodash.debounce'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  const [logoHeight, setLogoHeight] = useState(
    window.innerWidth >= 1024 ? window.innerHeight / 12 : 64
  )

  const changeLogoHeight = debounce(() => {
    setLogoHeight(window.innerWidth >= 1024 ? window.innerHeight / 12 : 64)
  }, 500)

  useEffect(() => {
    window.addEventListener('resize', changeLogoHeight)

    return () => {
      window.removeEventListener('resize', changeLogoHeight)
    }
  }, [])

  return (
    <header className="col-span-6 row-span-1 lg:grid lg:grid-cols-6 text-center py-3 lg:py-0 border-b border-gray-200">
      <div className="col-span-1 border-r border-gray-200">
        <Link to="/dashboard" className="block">
          <img
            src={Logo}
            alt="Antibiiotici"
            className="mx-auto select-none max-h-full p-2"
            style={{ height: logoHeight }}
          />
        </Link>
      </div>

      <div className="col-span-5 flex items-center justify-center px-5">
        <h1 className="text-blue font-medium text-xl">
          Antibiotici e aderenza terapeutica
        </h1>
        <div />
      </div>
    </header>
  )
}

export default Header
