import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import Api from 'Api/Api'
import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'
import { useCallback, useState } from 'react'
import ProductDetailColLeft from 'Components/ProductDetailColLeft/ProductDetailColLeft'
import { population } from 'Constants/population'
import ProductDetailColRight from 'Components/ProductDetailColRight/ProductDetailColRight'

const ProductDetail = () => {
  const { id } = useParams()

  const { isLoading, isError, data, error } = useQuery(
    ['product-detail', id],
    () => Api.getProductDetail(id)
  )

  const [activePopulation, setActivePopulation] = useState(population[0])

  const changeActivePopulation = useCallback(
    (val) => {
      if (val.id !== activePopulation.id) {
        setActivePopulation(val)
      }
    },
    [activePopulation]
  )

  if (isLoading) {
    return (
      <>
        <p className="w-full mt-5">Caricamento...</p>
      </>
    )
  }

  if (isError) {
    return (
      <>
        <div className="w-full mt-5">
          <ErrorAlert message={error?.message} />
        </div>
      </>
    )
  }

  return (
    <div className="bg-white rounded min-h-full flex flex-wrap text-sm">
      <div className="lg:border-r lg:border-gray-300 w-full lg:w-1/3 p-5">
        <ProductDetailColLeft
          name={data?.name}
          activePopulationId={activePopulation.id}
          changeActivePopulation={changeActivePopulation}
          activeNameId={data?.activeNameId}
          categoryName={data?.category?.name}
          formulations={data?.formulations}
          rcpUrl={data?.rcp?.url}
        />
      </div>

      <div className="w-full lg:w-2/3 p-5">
        <ProductDetailColRight activePopulation={activePopulation} />
      </div>
    </div>
  )
}

export default ProductDetail
