import PropTypes from 'prop-types'
import clsx from 'clsx'
import { memo } from 'react'

const Tag = ({ text, onClick = null, light = true }) => {
  return (
    <div
      className={clsx(
        'rounded p-2 cursor-pointer bg-blue mr-1 mb-1',
        light ? 'bg-opacity-10 text-blue' : 'text-white'
      )}
      onClick={onClick}>
      {text}
    </div>
  )
}

Tag.propTypes = {
  light: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default memo(Tag)
