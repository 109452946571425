import { NavLink } from 'react-router-dom'

const listItems = [
  {
    to: '/dashboard',
    text: 'Home',
  },
  {
    to: '/ricerca-avanzata',
    text: 'Ricerca avanzata',
  },
  {
    to: '/privacy-policy',
    text: 'Privacy',
  },
]

const Footer = () => {
  return (
    <footer className="col-span-6 row-span-1 sticky bottom-0 border-t border-gray-200 h-16 lg:h-auto">
      <ul className="flex items-center justify-center h-full">
        {listItems.map((item) => (
          <NavLink key={item.text} to={item.to} className="mx-2">
            <li>{item.text}</li>
          </NavLink>
        ))}
      </ul>
    </footer>
  )
}

export default Footer
