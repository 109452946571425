import Api from 'Api/Api'
import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'
import Label from 'Components/Label/Label'
import ListItem from 'Components/ListItem/ListItem'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'

const ActiveCategorySidebar = ({ id, onClose }) => {
  const { isLoading, isError, data, error } = useQuery(
    ['category-products', id],
    () => Api.getCategoryProducts(id)
  )

  return (
    <div className="bg-white fixed top-0 right-0 w-full lg:w-1/3 h-full shadow z-30 p-5 overflow-y-auto">
      <div className="flex items-center justify-between">
        <div>Risultati ricerca</div>
        <span
          className="material-icons md-3xl cursor-pointer text-gray-400"
          onClick={onClose}>
          close
        </span>
      </div>

      <div>
        {isLoading && <p>Caricamento...</p>}
        {isError && <ErrorAlert message={error?.message} />}
      </div>

      {data && (
        <>
          <div className="p-5">
            <Label text="Categoria" />
            <span>{data?.category?.name}</span>
          </div>

          {data?.products?.length > 0 ? (
            data?.products.map((product) => (
              <NavLink
                key={product.id}
                to={`/prodotti/${product.id}`}
                className="block mb-5">
                <ListItem
                  name={product.name}
                  subtitle={product.activeNameId}
                  icon={
                    <span className="material-icons text-blue md-3xl">
                      medical_services
                    </span>
                  }
                />
              </NavLink>
            ))
          ) : (
            <p>Non ci sono prodotti</p>
          )}
        </>
      )}
    </div>
  )
}

ActiveCategorySidebar.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ActiveCategorySidebar
