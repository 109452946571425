import PropTypes from 'prop-types'
import clsx from 'clsx'
import { memo } from 'react'
import config from 'Config/config'

const { API_URL } = config

const Pagination = ({ links, onChangePage }) => {
  const prev = links[0]
  const next = links[links.length - 1]

  const pages = links.slice(1, -1)

  const handleChangePage = (url) => {
    const newUrl = url.replace(API_URL, '')
    onChangePage(newUrl)
  }

  return (
    <div>
      <ul className="flex flex-wrap justify-center">
        <li
          onClick={() => handleChangePage(prev.url)}
          className={clsx(
            'flex items-center justify-center px-4 rounded cursor-pointer',
            !prev.url && 'opacity-50 pointer-events-none'
          )}>
          <span className="material-icons">navigate_before</span>
        </li>

        {pages.map((page) => (
          <li
            onClick={() => handleChangePage(page.url)}
            key={page.label}
            className={clsx(
              'flex items-center justify-center px-4 rounded cursor-pointer',
              page.active && 'bg-green text-white'
            )}>
            {page.label}
          </li>
        ))}

        <li
          onClick={() => handleChangePage(next.url)}
          className={clsx(
            'flex items-center justify-center px-4 rounded cursor-pointer',
            !next.url && 'opacity-50 pointer-events-none'
          )}>
          <span className="material-icons">navigate_next</span>
        </li>
      </ul>
    </div>
  )
}

Pagination.propTypes = {
  links: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
}

export default memo(Pagination)
