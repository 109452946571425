import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="text-center">
      <h2 className="mb-5">Questa pagina non esiste</h2>
      <Link to="/dashboard" className="bg-blue text-white p-3 rounded">
        Torna alla homepage
      </Link>
    </div>
  )
}

export default NotFound
