import axios from 'axios'

const deepFreeze = (object) => {
  const propNames = Object.getOwnPropertyNames(object)

  for (const name of propNames) {
    const value = object[name]

    if (value && typeof value === 'object') {
      deepFreeze(value)
    }
  }

  return Object.freeze(object)
}

export const getCustomErr = (err) => {
  return {
    message: err?.message,
    status: err?.['response.status'] || 0,
    statusText: err?.['response.statusText'] || 'Error',
    data: err?.['response.data'] || {},
  }
}

let instance = {}

const get = (url, params) => {
  return instance.get(url, { params }).catch((err) => {
    throw getCustomErr(err)
  })
}

const post = (url, data) => {
  return instance.post(url, data).catch((err) => {
    throw getCustomErr(err)
  })
}

const Api = {
  init: async (config = {}) => {
    return new Promise((resolve, reject) => {
      if (config.baseURL !== undefined) {
        instance = axios.create({
          baseURL: config.baseURL,
        })
      } else {
        throw new Error('baseURL undefined')
      }

      try {
        resolve()
      } catch (err) {
        reject(err)
      }
    })
  },

  setAccessToken: async (token = null) => {
    return new Promise((resolve, reject) => {
      if (!token) {
        throw new Error('token undefined')
      }

      if (typeof token !== 'string') {
        return new Error('You must provide a valid token')
      }

      instance.defaults.headers.common.Authorization = `Bearer ${token}`
      deepFreeze(instance)

      try {
        resolve()
        return null
      } catch (err) {
        reject(err)
        return null
      }
    })
  },

  auth: async (data) => {
    const resData = await post('/auth', data).then((res) => res.data)
    return resData
  },

  getItems: async (url) => {
    const resData = await get(url).then((res) => res.data)
    return resData
  },

  getProductDetail: async (productId) => {
    const resData = await get(`/products/${productId}`).then((res) => res.data)
    return resData
  },

  getTherapies: async (population, productId) => {
    const resData = await get(`/therapies/${population}/${productId}`).then(
      (res) => res.data
    )
    return resData
  },

  getMaterialProducts: async (materialId) => {
    const resData = await get(`/materials/${materialId}`).then(
      (res) => res.data
    )
    return resData
  },

  getCategoryProducts: async (categoryId) => {
    const resData = await get(`/categories/${categoryId}`).then(
      (res) => res.data
    )
    return resData
  },

  getSearchFilters: async () => {
    const resData = await get('/search/filters').then((res) => res.data)
    return resData
  },

  search: async (data) => {
    const resData = await post('/search', data).then((res) => res.data)
    return resData
  },
}

deepFreeze(Api)

export default Api
