import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'
import ListItem from 'Components/ListItem/ListItem'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const AdvancedSearchResultsSidebar = ({
  onClose,
  isLoading,
  isError,
  data = null,
  error = null,
}) => {
  return (
    <div className="bg-white fixed top-0 right-0 w-full lg:w-1/3 h-full shadow z-10 p-5 overflow-y-auto">
      <div className="flex items-center justify-between">
        <div>Risultati ricerca</div>
        <span
          className="material-icons md-3xl cursor-pointer text-gray-400"
          onClick={onClose}>
          close
        </span>
      </div>

      <div>
        {isLoading && <p>Caricamento...</p>}
        {isError && <ErrorAlert message={error?.message} />}
      </div>

      {data && (
        <>
          {data?.length > 0 ? (
            data?.map((product) => (
              <NavLink
                key={product.id}
                to={`/prodotti/${product.id}`}
                className="block mb-2">
                <ListItem
                  name={product.name}
                  subtitle={product.activeNameId}
                  icon={
                    <span className="material-icons text-blue md-3xl">
                      medical_services
                    </span>
                  }
                />
              </NavLink>
            ))
          ) : (
            <p>Non ci sono prodotti</p>
          )}
        </>
      )}
    </div>
  )
}

AdvancedSearchResultsSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  data: PropTypes.any,
  error: PropTypes.any,
}

export default AdvancedSearchResultsSidebar
