import PropTypes from 'prop-types'
import { memo } from 'react'

const InputSearch = ({ onChange }) => {
  return (
    <div className="relative">
      <input
        onChange={onChange}
        type="text"
        placeholder="Cerca"
        className="rounded w-full placeholder-gray-400 pr-16 bg-transparent border border-gray-200 text-gray-400 pl-2 py-2 relative z-20"
      />
      <div className="absolute z-10 right-0 top-0 bg-gray-200 border-t border-r border-b border-gray-200 h-full flex items-center px-3 rounded-r">
        <span className="material-icons md-block text-gray-400 transform rotate-90">
          search
        </span>
      </div>
    </div>
  )
}

InputSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default memo(InputSearch)
