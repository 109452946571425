import Aside from 'Layout/Aside/Aside'
import Footer from 'Layout/Footer/Footer'
import Header from 'Layout/Header/Header'
import Main from 'Layout/Main/Main'

const Layout = () => {
  return (
    <div className="lg:grid grid-cols-6 grid-rows-12 lg:h-screen overflow-hidden">
      <Header />
      <Aside />
      <Main />
      <Footer />
    </div>
  )
}

export default Layout
