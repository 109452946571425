import { NavLink } from 'react-router-dom'

const tabs = [
  {
    to: '/dashboard',
    text: 'Nome commerciale',
  },
  {
    to: '/dashboard/principio-attivo',
    text: 'Principio attivo',
  },
  {
    to: '/dashboard/categoria-farmacoterapeutica',
    text: 'Categoria farmacoterapeutica',
  },
]

const HomeTabs = () => {
  return (
    <ul className="flex sm:inline-flex flex-col sm:flex-row flex-wrap text-sm mx-auto flex-wrap border border-gray-200 rounded">
      {tabs.map((tab) => (
        <NavLink
          exact
          key={tab.text}
          to={tab.to}
          className="py-3 px-5 sm:first:border-r sm:first:border-gray-200 sm:last:border-l sm:last:border-gray-200 font-medium"
          activeClassName="text-blue bg-gray-200">
          <li>{tab.text}</li>
        </NavLink>
      ))}
    </ul>
  )
}

export default HomeTabs
