import Api from 'Api/Api'
import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'
import InputSearch from 'Components/InputSearch/InputSearch'
import ListItem from 'Components/ListItem/ListItem'
import Pagination from 'Components/Pagination/Pagination'
import debounce from 'lodash.debounce'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { NavLink } from 'react-router-dom'

const DEFAULT_URL = 'products?page=1'
const SEARCH_URL_PREFIX = 'search/string?type=product'

const Products = () => {
  const [url, setUrl] = useState(DEFAULT_URL)

  const { isLoading, isError, data, error } = useQuery(
    ['search-products', url],
    () => Api.getItems(url)
  )

  const changePage = useCallback((newUrl) => {
    setUrl(newUrl)
  }, [])

  const onSearch = debounce((e) => {
    setUrl(
      e.target.value
        ? `${SEARCH_URL_PREFIX}&string=${e.target.value}&page=1`
        : DEFAULT_URL
    )
  }, 250)

  const input = (
    <div className="mt-5 lg:mt-0">
      <InputSearch onChange={onSearch} />
    </div>
  )

  if (isLoading) {
    return (
      <>
        {input}
        <p className="w-full mt-5">Caricamento...</p>
      </>
    )
  }

  if (isError) {
    return (
      <>
        {input}
        <div className="w-full mt-5">
          <ErrorAlert message={error?.message} />
        </div>
      </>
    )
  }

  return (
    <>
      {input}

      <div className="w-full mt-5">
        {data?.data?.length > 0 ? (
          data?.data?.map((product) => (
            <NavLink
              key={product.id}
              to={`/prodotti/${product.id}`}
              className="block mb-5">
              <ListItem
                name={product?.name}
                subtitle={product?.activeNameId}
                icon={
                  <span className="material-icons text-blue md-3xl">
                    medical_services
                  </span>
                }
              />
            </NavLink>
          ))
        ) : (
          <p>Non ci sono risultati</p>
        )}

        {data?.data?.length > 0 && (
          <div className="flex justify-center">
            <Pagination links={data.links} onChangePage={changePage} />
          </div>
        )}
      </div>
    </>
  )
}

export default Products
