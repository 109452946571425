import Api from 'Api/Api'
import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'
import InputSearch from 'Components/InputSearch/InputSearch'
import ListItem from 'Components/ListItem/ListItem'
import Pagination from 'Components/Pagination/Pagination'
import ActiveMaterialSidebar from 'Layout/ActiveMaterialSidebar/ActiveMaterialSidebar'
import debounce from 'lodash.debounce'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'

const DEFAULT_URL = 'materials?page=1'
const SEARCH_URL_PREFIX = 'search/string?type=material'

const Materials = () => {
  const [url, setUrl] = useState(DEFAULT_URL)
  const [activeMaterialId, setActiveMaterialId] = useState(null)

  const { isLoading, isError, data, error } = useQuery(
    ['search-materials', url],
    () => Api.getItems(url)
  )

  const changePage = useCallback((newUrl) => {
    setUrl(newUrl)
  }, [])

  const onSearch = debounce((e) => {
    setUrl(
      e.target.value
        ? `${SEARCH_URL_PREFIX}&string=${e.target.value}&page=1`
        : DEFAULT_URL
    )
  }, 250)

  const changeActiveMaterial = useCallback((id) => {
    setActiveMaterialId(id)
  }, [])

  const input = (
    <div className="mt-5 lg:mt-0">
      <InputSearch onChange={onSearch} />
    </div>
  )

  if (isLoading) {
    return (
      <>
        {input}
        <p className="w-full mt-5">Caricamento...</p>
      </>
    )
  }

  if (isError) {
    return (
      <>
        {input}
        <div className="w-full mt-5">
          <ErrorAlert message={error?.message} />
        </div>
      </>
    )
  }

  return (
    <>
      {input}

      <div className="w-full mt-5">
        {data?.data?.length > 0 ? (
          data?.data?.map((material) => (
            <div key={material.id} className="mb-5">
              <ListItem
                name={material?.name}
                icon={
                  <span className="material-icons text-blue md-3xl">
                    medical_services
                  </span>
                }
                onClick={() => changeActiveMaterial(material.id)}
                className="cursor-pointer"
              />
            </div>
          ))
        ) : (
          <p>Non ci sono risultati</p>
        )}

        {data?.data?.length > 0 && (
          <div className="flex justify-center">
            <Pagination links={data.links} onChangePage={changePage} />
          </div>
        )}
      </div>

      {activeMaterialId && (
        <ActiveMaterialSidebar
          id={activeMaterialId}
          onClose={() => changeActiveMaterial(null)}
        />
      )}
    </>
  )
}

export default Materials
