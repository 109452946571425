import { ReactComponent as IconPill } from 'Assets/icon-pill.svg'
import { ReactComponent as IconCategory } from 'Assets/icon-category.svg'
import { ReactComponent as IconAge } from 'Assets/icon-age.svg'
import { ReactComponent as IconFormulation } from 'Assets/icon-formulation.svg'
import { ReactComponent as IconIndication } from 'Assets/icon-indication.svg'
import Api from 'Api/Api'
import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'
import { useQuery, useMutation } from 'react-query'
import { useCallback, useState } from 'react'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import AdvancedSearchResultsSidebar from 'Layout/AdvancedSearchResultsSidebar/AdvancedSearchResultsSidebar'
import ListItem from 'Components/ListItem/ListItem'

const mappedKeys = {
  active_materials: {
    name: 'Principio attivo',
    icon: <IconPill width={25} />,
  },
  categories: {
    name: 'Categoria farmacoterapeutica',
    icon: <IconCategory width={25} />,
  },
  eta: {
    name: "Fascia d'età",
    icon: <IconAge width={25} />,
  },
  formulations: {
    name: 'Formulazione',
    icon: <IconFormulation width={25} />,
  },
  indications: {
    name: 'Indicazioni',
    icon: <IconIndication width={25} />,
  },
}

const AdvancedSearch = () => {
  const { register, handleSubmit, reset } = useForm()

  const { isLoading, isError, data, error } = useQuery(['search-filters'], () =>
    Api.getSearchFilters()
  )

  const mutation = useMutation((params) => Api.search(params))

  const [activeFilter, setActiveFilter] = useState(null)
  const [showResults, setShowResults] = useState(null)

  const changeFilter = useCallback((filter) => {
    setActiveFilter((f) => (f === filter ? null : filter))
  }, [])

  const [err, setErr] = useState(null)

  if (isLoading) {
    return <p>Caricamento...</p>
  }

  if (isError) {
    return <ErrorAlert message={error?.message} />
  }

  const keys = Object.keys(data)
  const values = Object.values(data)

  const onSubmit = async (formData) => {
    try {
      setShowResults(true)

      const params = formData.params.filter((item) => item)
      const parsedParams = params.map((p) => JSON.parse(p))

      await mutation.mutate({ params: parsedParams })
    } catch (e) {
      setErr(e)
    }

    return data
  }

  return (
    <>
      <div className="mb-5">Ricerca con filtri</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {keys.map((key, i) => {
          return (
            <div key={key}>
              <div className="mb-3">
                <ListItem
                  name={mappedKeys[key]?.name}
                  subtitle={
                    <div className={clsx(activeFilter === key && 'text-black')}>
                      {values[i]?.length}
                    </div>
                  }
                  icon={mappedKeys[key]?.icon}
                  onClick={() => changeFilter(key)}
                  className={clsx(
                    'flex items-center p-3 cursor-pointer mb-3 rounded text-sm',
                    activeFilter === key ? 'bg-green' : 'bg-white'
                  )}
                />
              </div>

              <div
                className={clsx(
                  'max-h-60 overflow-y-auto mb-5',
                  activeFilter === key ? 'block' : 'hidden'
                )}>
                {values[i].map((option) => {
                  return (
                    <div key={option.id} className="py-2">
                      <label
                        className="inline-flex items-center cursor-pointer"
                        htmlFor={option.name}>
                        <div className="h-5">
                          <input
                            type="radio"
                            name={`params[${i}]`}
                            defaultValue={JSON.stringify({
                              id: option.id,
                              type: key,
                            })}
                            id={option.name}
                            className="w-5 h-full cursor-pointer"
                            ref={register()}
                          />
                        </div>
                        <span className="ml-2">{option.name}</span>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}

        {err && (
          <div className="mb-5">
            <ErrorAlert message={err?.message} />
          </div>
        )}

        <button
          type="button"
          className="text-blue p-3 rounded w-full mx-auto block disabled:opacity-50 disabled:pointer-events-none"
          disabled={mutation.isLoading}
          onClick={reset}>
          Cancella i filtri
        </button>

        <button
          type="submit"
          className="bg-green p-3 rounded w-full mx-auto block uppercase disabled:opacity-50 disabled:pointer-events-none"
          disabled={mutation.isLoading}>
          {mutation.isLoading ? 'Caricamento...' : 'Mostra i risultati'}
        </button>
      </form>

      {showResults && (
        <AdvancedSearchResultsSidebar
          onClose={() => setShowResults(false)}
          isLoading={mutation.isLoading}
          isError={mutation.isError}
          error={mutation.error}
          data={mutation.data}
        />
      )}
    </>
  )
}

export default AdvancedSearch
