import PropTypes from 'prop-types'

const Label = ({ text }) => {
  return <p className="text-gray-400">{text}</p>
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Label
