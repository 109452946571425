import { Component, memo } from 'react'
import PropTypes from 'prop-types'
import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
    }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error })
    // eslint-disable-next-line no-console
    console.log({ error, info })
  }

  render() {
    const { hasError, error } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <ErrorAlert message={error?.message || 'Si è verificato un errore'} />
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
}

export default memo(ErrorBoundary)
