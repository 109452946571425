import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import './index.css'
import config from 'Config/config'
import Api from 'Api/Api'
import { BrowserRouter, HashRouter } from 'react-router-dom'
import AccessContextProvider from 'Context/AccessContext'
import Head from 'Layout/Head/Head'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { isElectron } from 'Utils/isElectron'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@reach/accordion/styles.css'

const { API_URL } = config

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const Router = ({ children }) =>
  isElectron() ? (
    <HashRouter>{children}</HashRouter>
  ) : (
    <BrowserRouter>{children}</BrowserRouter>
  )

Router.propTypes = {
  children: PropTypes.node.isRequired,
}

const AppWrapper = () => (
  <React.StrictMode>
    <Router>
      <>
        <Head />

        <AccessContextProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </AccessContextProvider>
      </>
    </Router>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

const initApp = async () => {
  await Api.init({ baseURL: API_URL })

  ReactDOM.render(<AppWrapper />, document.getElementById('root'))
}

;(async () => {
  await initApp()
})().catch((err) => {
  document.body.innerHTML = err.message
})
