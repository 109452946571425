export const population = [
  {
    id: '0',
    text: 'Adulti',
  },
  {
    id: '1',
    text: 'Età pediatrica',
  },
  {
    id: '2',
    text: 'Anziani',
  },
]
