// import config from 'Config/config'
import { Helmet } from 'react-helmet'

const Head = () => {
  // const { NODE_ENV } = config

  return (
    <Helmet>
      <title>Antibiotici</title>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"
        rel="stylesheet"
      />

      {/* {NODE_ENV === 'production' && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-7GZMHNPSW3"
        />
      )}

      {NODE_ENV === 'production' && (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
  
            function gtag(){
              dataLayer.push(arguments);
            }
            
            gtag('js', new Date());
  
            gtag('config', 'G-7GZMHNPSW3');
          `}
        </script>
      )} */}
    </Helmet>
  )
}

export default Head
