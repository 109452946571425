import Label from 'Components/Label/Label'
import ProductInfo from 'Components/ProductInfo/ProductInfo'
import Tag from 'Components/Tag/Tag'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'
import { memo } from 'react'
import { population } from 'Constants/population'

const ProductDetailColLeft = ({
  name,
  activePopulationId,
  changeActivePopulation,
  activeNameId,
  categoryName,
  formulations,
  rcpUrl,
}) => {
  return (
    <>
      <h2 className="mb-5 text-lg font-semibold">{name}</h2>

      <div className="mb-2">
        <Label text="Seleziona fascia d'età" />
      </div>

      <div className="flex items-center flex-wrap mb-5">
        {population.map((item) => (
          <Tag
            key={item.id}
            text={item.text}
            light={activePopulationId !== item.id}
            onClick={() => changeActivePopulation(item)}
          />
        ))}
      </div>

      <div className="mb-5">
        <Label text="Informazioni generali" />
      </div>

      <div className="px-2 mb-5">
        <div className="mb-2">
          <Label text="Principio attivo" />
          <ProductInfo text={activeNameId || '-'} />
        </div>

        <div className="mb-2">
          <Label text="Categoria farmacoterapeurica" />
          <ProductInfo text={categoryName || '-'} />
        </div>

        {formulations?.length > 0 && (
          <div className="mb-2">
            <Label text="Formulazione" />
            <Accordion collapsible className="accordion-blue">
              {formulations?.map((formulation) => (
                <AccordionItem key={formulation?.id} className="mb-2 w-full">
                  <AccordionButton className="text-left flex items-center justify-between w-full">
                    <ProductInfo text={formulation?.value} />
                    <span className="material-icons-outlined text-blue md-expand">
                      expand_more
                    </span>
                  </AccordionButton>

                  <AccordionPanel>
                    <div className="mb-2">
                      <Label text="Modo di somministrazione" />
                      <div>{formulation?.administration}</div>
                    </div>

                    <div>
                      <Label text="Controindicazioni" />
                      <div>{formulation?.side_effect}</div>
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        )}
      </div>

      {rcpUrl && (
        <div className="flex items-center justify-center lg:px-10">
          <a
            href={rcpUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue text-white p-3 font-semibold rounded uppercase block w-full text-center">
            Vai all&apos;rcp
          </a>
        </div>
      )}
    </>
  )
}

ProductDetailColLeft.propTypes = {
  name: PropTypes.string.isRequired,
  activePopulationId: PropTypes.string.isRequired,
  changeActivePopulation: PropTypes.func.isRequired,
  activeNameId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  formulations: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  rcpUrl: PropTypes.string.isRequired,
}

export default memo(ProductDetailColLeft)
