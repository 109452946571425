import Api from 'Api/Api'
import useAccessContext from 'Hooks/useAccessContext'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { isElectron } from 'Utils/isElectron'
import './access-code.css'
import LogoOutline from 'Assets/logo-outline.png'

const ACCESS_CODES = ['ESPERIENZA CLINICA', 'ANTIBIOTICI', 'AxensoTest']

const AccessCode = () => {
  const { register, handleSubmit, errors, formState } = useForm()
  const { access } = useAccessContext()

  const { isSubmitting } = formState

  const [err, setErr] = useState(null)

  const mutation = useMutation((data) => Api.auth(data), {
    onSuccess: () => access(),
    onError: () =>
      setErr('Si è verificato un errore. Si prega di riprovare più tardi.'),
  })

  const onSubmit = async (values) => {
    if (ACCESS_CODES.indexOf(values.access_code) !== -1) {
      try {
        await mutation.mutate({
          code: values.access_code,
          device: isElectron() ? 'desktop' : 'web',
        })
      } catch (error) {
        setErr(error)
      }
    } else {
      setErr('Codice errato.')
    }

    return values
  }

  return (
    <div className="grid items-center justify-center overflow-y-auto h-screen bg-gray-100 px-10 bg-center bg-no-repeat bg-auto access-code-container">
      <div className="access-code p-10 shadow-xl rounded-xl mx-auto bg-white">
        <form onSubmit={handleSubmit(onSubmit)}>
          <img
            src={LogoOutline}
            alt="antibiotici"
            className="block mx-auto mb-5"
          />

          <h1 className="font-bold text-center access-code-title mb-7 text-3xl">
            Antibiotici e<br />
            appropriatezza terapeutica
          </h1>

          <h2 className="font-medium font-medium text-center mb-7 text-xl">
            Per accedere al servizio inserisci la
            <br /> password
          </h2>

          <div className="w-full mx-auto">
            <input
              type="text"
              placeholder="Password"
              id="access_code"
              name="access_code"
              className="block rounded-xl w-full py-4 px-3 border mb-5 bg-gray-200 placeholder-gray-500::placeholder font-medium text-lg"
              ref={register({
                required:
                  'Il codice di accesso è richiesto per accedere al sito',
              })}
            />

            {errors.access_code && (
              <div className="text-red-500 mb-5">
                {errors.access_code.message}
              </div>
            )}

            {err && <div className="text-red-500 mb-5">{err}</div>}

            <button
              type="submit"
              className="bg-green px-3 py-4 rounded-xl w-full mx-auto block uppercase disabled:opacity-50 disabled:pointer-events-none font-semibold text-lg"
              disabled={isSubmitting}>
              {isSubmitting ? 'Caricamento...' : 'Accedi'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AccessCode
