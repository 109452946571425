import Label from 'Components/Label/Label'
import PropTypes from 'prop-types'
import { memo } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'
import { useParams } from 'react-router-dom'
import Api from 'Api/Api'
import { useQuery } from 'react-query'
import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'
import { v4 } from 'uuid'
import ProductInfo from 'Components/ProductInfo/ProductInfo'

const ProductDetailColRight = ({ activePopulation }) => {
  const { id } = useParams()

  const { isLoading, isError, data, error } = useQuery(
    ['therapies', activePopulation.id, id],
    () => Api.getTherapies(activePopulation.id, id)
  )

  if (isLoading) {
    return (
      <>
        <p className="w-full mt-5">Caricamento...</p>
      </>
    )
  }

  if (isError) {
    return (
      <>
        <div className="w-full mt-5">
          <ErrorAlert message={error?.message} />
        </div>
      </>
    )
  }

  const formattedData = data.map((item) => {
    const subItemsKeys = Object.keys(item?.SubItems) || []

    const subItems = Object.values(item?.SubItems).map((values, i) => {
      return values?.map((value) => {
        const subindication = value?.formulations ? subItemsKeys?.[i] : null

        return {
          subindication,
          values: value,
        }
      })
    })

    return {
      id: item?.id,
      indication_text: item?.indication_text,
      subItems,
    }
  })

  return (
    <>
      <div className="mb-2">
        <Label text={`Indicazione e posologia - ${activePopulation.text}`} />
      </div>

      <Accordion collapsible className="accordion-green">
        {formattedData.length > 0 ? (
          formattedData.map((therapy) => {
            return (
              <AccordionItem className="accordion-green-item" key={therapy?.id}>
                <AccordionButton className="text-left px-3 py-1 rounded w-full bg-gray-100">
                  {therapy?.indication_text}
                </AccordionButton>

                <AccordionPanel className="mt-2 p-3 rounded">
                  <Accordion collapsible>
                    {therapy?.subItems?.map((subItems) => {
                      return (
                        <AccordionItem key={v4()}>
                          <AccordionButton className="text-left flex items-center justify-between w-full">
                            <div>
                              {subItems?.[0]?.subindication ? (
                                <>
                                  <span className="text-blue">
                                    Sottoindicazioni:{' '}
                                  </span>
                                  <span>{subItems?.[0]?.subindication}</span>
                                </>
                              ) : (
                                <>
                                  <span className="text-blue">
                                    Formulazione:{' '}
                                  </span>
                                  <span>{subItems?.[0]?.values?.value}</span>
                                </>
                              )}
                            </div>
                            <span className="material-icons-outlined text-blue md-expand">
                              expand_more
                            </span>
                          </AccordionButton>

                          <AccordionPanel className="mt-3 mb-5">
                            {subItems?.[0]?.subindication ? (
                              <Accordion collapsible>
                                {subItems?.map((item) => {
                                  const formulations =
                                    item?.values?.formulations

                                  return (
                                    <AccordionItem key={v4()} className="px-0">
                                      <AccordionButton className="text-left flex items-center justify-between w-full">
                                        <span>{formulations?.value}</span>
                                        <span className="material-icons-outlined text-blue md-expand">
                                          expand_more
                                        </span>
                                      </AccordionButton>

                                      <AccordionPanel className="mt-2">
                                        {!formulations?.items?.[
                                          'daily-dose-mg'
                                        ] &&
                                          !formulations?.items?.[
                                            'treatment-duration'
                                          ] &&
                                          !formulations?.items?.note && (
                                            <Label text="-" />
                                          )}

                                        {formulations?.items?.[
                                          'daily-dose-mg'
                                        ] && (
                                          <div className="mb-2">
                                            <Label text="Dose giornaliera" />
                                            <ProductInfo
                                              text={
                                                formulations?.items?.[
                                                  'daily-dose-mg'
                                                ]
                                              }
                                            />
                                          </div>
                                        )}

                                        {formulations?.items?.[
                                          'treatment-duration'
                                        ] && (
                                          <div className="mb-2">
                                            <Label text="Durata totale del trattamento" />
                                            <ProductInfo
                                              text={
                                                formulations?.items?.[
                                                  'treatment-duration'
                                                ]
                                              }
                                            />
                                          </div>
                                        )}

                                        {formulations?.items?.note && (
                                          <div className="mb-2">
                                            <Label text="Note" />
                                            <ProductInfo
                                              text={formulations?.items?.note}
                                            />
                                          </div>
                                        )}
                                      </AccordionPanel>
                                    </AccordionItem>
                                  )
                                })}
                              </Accordion>
                            ) : (
                              subItems.map((item) => {
                                const items = item?.values?.items

                                return (
                                  <div key={v4()}>
                                    {items?.['daily-dose-mg'] &&
                                      items?.['treatment-duration'] &&
                                      items?.note && <Label text="-" />}

                                    {items?.['daily-dose-mg'] && (
                                      <div className="mb-2">
                                        <Label text="Dose giornaliera" />
                                        <ProductInfo
                                          text={items?.['daily-dose-mg']}
                                        />
                                      </div>
                                    )}

                                    {items?.['treatment-duration'] && (
                                      <div className="mb-2">
                                        <Label text="Durata totale del trattamento" />
                                        <ProductInfo
                                          text={items?.['treatment-duration']}
                                        />
                                      </div>
                                    )}

                                    {items?.note && (
                                      <div className="mb-2">
                                        <Label text="Note" />
                                        <ProductInfo text={items?.note} />
                                      </div>
                                    )}
                                  </div>
                                )
                              })
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </AccordionPanel>
              </AccordionItem>
            )
          })
        ) : (
          <p>Nessun dato disponibile</p>
        )}
      </Accordion>
    </>
  )
}

ProductDetailColRight.propTypes = {
  activePopulation: PropTypes.object.isRequired,
}

export default memo(ProductDetailColRight)
