import Api from 'Api/Api'
import ErrorAlert from 'Components/ErrorAlert/ErrorAlert'
import InputSearch from 'Components/InputSearch/InputSearch'
import ListItem from 'Components/ListItem/ListItem'
import Pagination from 'Components/Pagination/Pagination'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import debounce from 'lodash.debounce'

const DEFAULT_URL = 'rcp?page=1'
const SEARCH_URL_PREFIX = 'search/string?type=rcp'

const Rcp = () => {
  const [url, setUrl] = useState(DEFAULT_URL)

  const { isLoading, isError, data, error } = useQuery(
    ['search-rcp', url],
    () => Api.getItems(url)
  )

  const changePage = useCallback((newUrl) => {
    setUrl(newUrl)
  }, [])

  const onSearch = debounce((e) => {
    setUrl(
      e.target.value
        ? `${SEARCH_URL_PREFIX}&string=${e.target.value}&page=1`
        : DEFAULT_URL
    )
  }, 250)

  const input = (
    <div className="mb-5 w-full lg:w-1/2">
      <InputSearch onChange={onSearch} />
    </div>
  )

  if (isLoading) {
    return (
      <>
        {input}
        <p className="w-full mt-5">Caricamento...</p>
      </>
    )
  }

  if (isError) {
    return (
      <>
        {input}
        <div className="w-full mt-5">
          <ErrorAlert message={error?.message} />
        </div>
      </>
    )
  }

  return (
    <>
      {input}

      {data?.data?.length > 0 ? (
        data?.data?.map((rcp) => (
          <div key={rcp.id} className="mb-5">
            <ListItem
              name={rcp.name}
              icon={
                <span className="material-icons text-blue md-3xl">
                  medical_services
                </span>
              }
              className="cursor-pointer"
              onClick={() => window.open(rcp.url, '_blank')}
            />
          </div>
        ))
      ) : (
        <p>Non ci sono risultati</p>
      )}

      {data?.data?.length > 0 && (
        <div className="flex justify-center">
          <Pagination links={data.links} onChangePage={changePage} />
        </div>
      )}
    </>
  )
}

export default Rcp
