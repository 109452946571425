const Privacy = () => {
  return (
    <div>
      <p className="mb-5">
        Nellʼambito dellʼespletamento del servizio reso, la società Axenso
        S.r.l., con sede legale in Milano, via Gallarate 106, acquisisce notizie
        riservate dagli utenti. Pertanto, conformemente a quanto previsto e
        contemplato dal D. Lgs. n. 196/2003 e dal Regolamento UE n. 2016/679,
        recante disposizioni per la tutela delle persone e di altri soggetti
        rispetto al trattamento dei dati personali, la stessa, quale titolare
        del trattamento, è tenuta ad illustrare le modalità di utilizzo delle
        informazioni in suo possesso. A tale riguardo, ai sensi dell’articolo 13
        del D. Lgs. n. 196/2003 e dell’art. 13 del Regolamento UE n. 2016/679,
        si precisa che:
      </p>

      <p className="mb-5">
        il trattamento dei dati in parola avviene con procedure idonee a
        garantire il rispetto del diritto alla riservatezza dell’utente e si
        articola nella loro raccolta, registrazione, organizzazione,
        conservazione, elaborazione, modificazione, selezione, estrazione,
        raffronto, utilizzo, interconnessione, blocco, comunicazione,
        cancellazione e distribuzione, anche mediante combinazione di due o più
        delle operazioni predette; il trattamento stesso ha finalità unicamente
        connesse o strumentali al servizio fornito, e precisamente quelle di:
      </p>

      <ul className="mb-5 ">
        <li>
          - raccogliere dati ed informazioni in via generale e particolare sugli
          orientamenti e le preferenze dell’utente;
        </li>
        <li>
          - inviare messaggi a contenuto meramente informativo o contenenti
          offerte commerciali;
        </li>
        <li>- inviare materiale pubblicitario e informativo;</li>
        <li>- effettuare comunicazioni commerciali, anche interattive;</li>
        <li>
          - compiere attività dirette di collocamento di prodotti o servizi;
        </li>
        <li>
          - elaborare studi e ricerche statistiche su vendite, clienti e altre
          informazioni, ed eventualmente comunicare le stesse a terzi.
        </li>
      </ul>

      <p className="mb-5">
        In relazione alle sopradescritte finalità, il trattamento dei dati
        personali avrà luogo prevalentemente con modalità automatizzate ed
        informatizzate, con logiche strettamente correlate alle finalità stesse
        e, comunque, sempre nel rispetto delle regole di riservatezza e di
        sicurezza previste dalla normativa vigente. I dati saranno conservati,
        per i termini di legge, presso la sede operativa della Axenso S.r.l., in
        via Gallarate 106, Milano, e trattati da parte di dipendenti e/o
        professionisti da questa incaricati, i quali svolgono le suddette
        attività sotto la diretta supervisione del Titolare del trattamento. A
        tal fine, i dati stessi potranno essere trasmessi a soggetti esterni che
        svolgono funzioni strettamente connesse e strumentali all’operatività
        del servizio
      </p>

      <p className="mb-5">
        Il conferimento del consenso al trattamento da parte dell’utente è
        facoltativo. Un eventuale rifiuto ad inserire le informazioni richieste
        nella pagina dedicata alla registrazione rende, però, impossibile
        lʼutilizzo del servizio. Alla società Axenso S.r.l., titolare del
        trattamento, lʼutente potrà rivolgersi per far valere i suoi diritti
        così come previsti dallʼart. 7 del D. Lgs. n. 196/2003 e 15 del
        Regolamento UE n. 2016/679.
      </p>
    </div>
  )
}

export default Privacy
