const Info = () => {
  return (
    <div>
      <p className="mb-5">
        <span className="font-bold">Antibiotici</span> è un’applicazione per la
        formazione e l’aggiornamento della classe medica che opera sul
        territorio nazionale italiano. È rivolta a tutta la classe medica e, in
        particolar modo, al Medico di Medicina Generale.
      </p>

      <p className="mb-5">
        <span className="font-bold">Antibiotici</span> non è un dispositivo
        medico, e non ha come intento quello di dare suggerimenti sui
        trattamenti da utilizzare nella pratica clinica.
      </p>

      <p className="mb-5">
        Le informazioni contenenti sono state ricavate dai corrispettivi
        Riassunti delle Caratteristiche del Prodotto presenti in Banca Dati
        Farmaco dell’AIFA su{' '}
        <a
          href="https://farmaci.agenziafarmaco.gov.it/bancadatifarmaci/cerca-farmaco"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue">
          https://farmaci.agenziafarmaco.gov.it/bancadatifarmaci/cerca-farmaco
        </a>{' '}
        e sono valide solo sul territorio nazionale italiano.
        <br /> Tutte le informazioni presenti all’interno di Antibiotici saranno
        aggiornate con cadenza annuale.
      </p>

      <p className="mb-5">
        Gli sviluppatori ritengono che le informazioni presenti in{' '}
        <span className="font-bold">Antibiotici</span> siano accurate, tuttavia
        non escludono la presenza di errori. Qualora riscontrasse delle
        incongruenze o errori la preghiamo di segnalarle al seguente indirizzo
        mail:{' '}
        <a href="mailto:info@axenso.com" className="text-blue">
          info@axenso.com
        </a>
      </p>

      <p className="mb-5">
        <span className="font-bold">Antibiotici</span> è un prodotto sviluppato
        da Axenso - Milano.
      </p>

      <p className="mb-5">
        L’utente è responsabile dell’utilizzo delle informazioni contenute in
        Antibiotici.
        <br /> Qualora un suo paziente dovesse presentare delle reazioni o
        eventi avversi durante l’utilizzo degli antibiotici, la preghiamo di
        seguire la procedura di segnalazione degli eventi avversi riportata sul
        sito{' '}
        <a
          href="https://www.aifa.gov.it/content/segnalazioni-reazioni-avverse"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue">
          https://www.aifa.gov.it/content/segnalazioni-reazioni-avverse
        </a>
      </p>
    </div>
  )
}

export default Info
