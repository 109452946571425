import HomeTabs from 'Components/HomeTabs/HomeTabs'
import Categories from 'Pages/Categories/Categories'
import Materials from 'Pages/Materials/Materials'
import Products from 'Pages/Products/Products'
import { Route, Switch } from 'react-router-dom'

const routes = [
  {
    path: '/dashboard',
    component: () => <Products />,
  },
  {
    path: '/dashboard/principio-attivo',
    component: () => <Materials />,
  },
  {
    path: '/dashboard/categoria-farmacoterapeutica',
    component: () => <Categories />,
  },
]

const Home = () => {
  return (
    <div className="lg:flex lg:justify-between lg:items-center lg:flex-wrap">
      <div>
        <HomeTabs />
      </div>

      <Switch>
        {routes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </div>
  )
}

export default Home
