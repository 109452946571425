const { NODE_ENV, REACT_APP_API_URL } = process.env

const config = {
  NODE_ENV,
  API_URL: REACT_APP_API_URL,
}

Object.freeze(config)

export default config
