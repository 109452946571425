import PropTypes from 'prop-types'

const ProductInfo = ({ text = '-' }) => {
  return (
    <div className="flex items-center">
      <div className="mr-2">
        <span className="material-icons-outlined text-blue">done</span>
      </div>

      <div>{text}</div>
    </div>
  )
}

ProductInfo.propTypes = {
  text: PropTypes.string.isRequired,
}

export default ProductInfo
