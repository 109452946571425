import PropTypes from 'prop-types'
import { memo } from 'react'
import clsx from 'clsx'

const ListItem = ({
  icon = null,
  name,
  subtitle = null,
  onClick = null,
  className = null,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx('bg-white rounded flex items-center p-5', className)}>
      {icon && <div className="mr-5">{icon}</div>}
      <div>
        <div>{name}</div>
        {subtitle && <div className="text-gray-400">{subtitle}</div>}
      </div>
    </div>
  )
}

ListItem.propTypes = {
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default memo(ListItem)
