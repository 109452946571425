import ErrorBoundary from 'Components/ErrorBoundary/ErrorBoundary'
import AdvancedSearch from 'Pages/AdvancedSearch/AdvancedSearch'
import Home from 'Pages/Home/Home'
import Info from 'Pages/Info/Info'
import NotFound from 'Pages/NotFound/NotFound'
import Privacy from 'Pages/Privacy/Privacy'
import ProductDetail from 'Pages/ProductDetail/ProductDetail'
import Rcp from 'Pages/Rcp/Rcp'
import { Redirect, Route, Switch } from 'react-router-dom'

const routes = [
  {
    path: '/dashboard',
    component: <Home />,
    id: 1,
  },
  {
    exact: true,
    path: '/ricerca-avanzata',
    component: <AdvancedSearch />,
    id: 2,
  },
  {
    exact: true,
    path: '/rcp',
    component: <Rcp />,
    id: 3,
  },
  {
    exact: true,
    path: '/info',
    component: <Info />,
    id: 4,
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: <Privacy />,
    id: 5,
  },
  {
    exact: true,
    path: '/prodotti/:id',
    component: <ProductDetail />,
    id: 6,
  },
  {
    component: <NotFound />,
    id: 7,
  },
]

const Main = () => {
  return (
    <main className="lg:col-span-5 lg:row-span-10 shadow-inner bg-gray-100 p-5 overflow-y-auto">
      <ErrorBoundary>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />

          {routes.map((route) => (
            <Route
              key={route.id}
              exact={route.exact}
              path={route.path}
              component={() => route.component}
            />
          ))}
        </Switch>
      </ErrorBoundary>
    </main>
  )
}

export default Main
