import { createContext, useCallback, useReducer } from 'react'
import PropTypes from 'prop-types'
import store from 'store'

const storedAccess = store.get('can-access')

const actionTypes = {
  ACCESS: 'ACCESS',
}

const initialState = {
  canAccess: storedAccess || false,
}

const accessReducer = (state, action) => {
  const { type } = action

  switch (type) {
    case actionTypes.ACCESS:
      return {
        ...state,
        canAccess: true,
      }

    default:
      return state
  }
}

export const AccessContext = createContext(initialState)

const reducer = (state, action) => accessReducer(state, action)

const AccessContextProvider = ({ children }) => {
  const [accessState, dispatch] = useReducer(reducer, initialState)

  const access = useCallback(async () => {
    dispatch({
      type: actionTypes.ACCESS,
    })

    await store.set('can-access', true)
  }, [])

  return (
    <AccessContext.Provider value={{ accessState, access }}>
      {children}
    </AccessContext.Provider>
  )
}

AccessContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AccessContextProvider
